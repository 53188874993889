import $ from 'jquery'

const $doc = $(document)

function toggleTabItem() {
  const targetContent = $(this).data('target')
  let el = $(this);

  el.parent().parent().find('.js-tab-item').removeClass('active').attr('aria-selected', 'false').attr('tabindex', '-1')
  el.parent().parent().find('.js-tab-content').removeClass('active').attr('hidden', 'true')

  el.addClass('active').attr('aria-selected', 'true').removeAttr('tabindex')
  $(`#${targetContent}`).addClass('active').removeAttr('hidden')
}

export default function initTabs(){
  $doc.on('click', '.js-tab-item', toggleTabItem )
  $( '.js-tabs' ).each(function() {
    var index = 0;
    var $tabs = $(this).find('.js-tab-item');
  
    $tabs.bind(
    {
      keydown: function(ev){
        var LEFT_ARROW = 37;
        var UP_ARROW = 38;
        var RIGHT_ARROW = 39;
        var DOWN_ARROW = 40;
  
        var k = ev.which || ev.keyCode;
  
        if (k >= LEFT_ARROW && k <= DOWN_ARROW){

          if (k == LEFT_ARROW || k == UP_ARROW){
            if (index > 0) {
              index--;
            }
            else {
              index = $tabs.length - 1;
            }
          }
  
          else if (k == RIGHT_ARROW || k == DOWN_ARROW){
            if (index < ($tabs.length - 1)){
              index++;
            }
            else {
              index = 0;
            }
          }
  
          $($tabs.get(index)).click();
          ev.preventDefault();
        }
      },
  
      click: function(ev){
        index = $.inArray(this, $tabs.get());
        setFocus();
        ev.preventDefault();
      }
    });
  
    var setFocus = function(){

      $tabs.attr(
      {
        tabindex: '-1',
        'aria-selected': 'false'
      }).removeClass('active');
  
      $(this).find('.js-tab-contet').removeClass('active').attr('hidden', 'true');
  
      $($tabs.get(index)).attr(
      {
        tabindex: '0',
        'aria-selected': 'true'
      }).addClass('active').focus();

      $($($tabs.get(index)).data('target')).addClass('active').removeAttr('hidden');
    };
  });
}
