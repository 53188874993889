import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

// this is just an example of functionality, opens only one static popup with #popup ID
function openModal(e) {
  const targetModal = e.target.dataset.targetModal;

  $.magnificPopup.open({
    items: {
      src: `#${targetModal}`,
      type: 'inline',
    },
    closeBtnInside: true
  });
}

function videoModal(e) {
  e.preventDefault();
  var videoItem = $(e.currentTarget);
  var videoSrc = videoItem.data('video');

  $.magnificPopup.open({
    items: {
      src: videoSrc,
      type: 'iframe',
    },
    midClick: true,
    removalDelay: 100,
    mainClass: 'popup--animate',
    iframe: {
      markup: '<div class="mfp-video-header">' +
        '<div class="mfp-close"></div>' +
        '</div>' +
        '<div class="mfp-iframe-scaler">' +
        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
        '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
        }
      }
    }
  });
}

export default function initMagnificPopup(){
  $doc.on('click', '.js-open-modal', openModal );
  $doc.on('click', '.js-video-modal', videoModal );
}
