import $ from 'jquery'

export default function initScrollTo(){
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    
    window.addEventListener("DOMContentLoaded", function() {
        var pageHash = window.location.hash.toString().split('#')[1]
        
        pageHash && document.getElementById(pageHash).scrollIntoView({
            behavior: 'smooth'
        });
    }, false);
}
