import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)


function openStylebookModal(name) {
  var options = '', screenshots = '', upgrades = '';
  var json = require(`../../stylebook-data/${name}/options.json`);

  for (var key in json.options) { options += `<li>${json.options[key]}</li>` }
  for (var key in json.upgrades) { upgrades += `<li>${json.upgrades[key]}</li>` }
  for (var key in json.images) { screenshots += `<img src='/wp-content/themes/_mgstarter/stylebook-data/${name}/img/${json.images[key]}'>` }

  $.magnificPopup.open({
    items: {
      type: 'inline',
      src: `
        <div class="stylebook__popup">
          <h1>Block Name: ${json.name}</h1>
          <div class="stylebook__popup__inner">
             <div class="stylebook__popup__inner__part">
              <h2>Description:</h2>
              <p>${json.desc}</p>
             </div>
             <div class="stylebook__popup__inner__part">
               <h2>Block Options</h2>
               <ul>${options}</ul>
             </div>
             <div class="stylebook__popup__inner__part">
               <h2>Easy to add in future</h2>
               <ul>${upgrades}</ul>
             </div>
             <div class="stylebook__popup__inner__full">
               <h2>Previews</h2>
               <div class="stylebook__screenshots">
                ${screenshots}
               </div>
             </div>
          </div>
        </div>`
    },
    closeBtnInside: true
  });
}

function getBlockType(e){
  var blockName = $(this).attr('block-name');
  openStylebookModal(blockName)
}

export default function initStylebook(){
  $doc.on('click', '.js-stylebook', getBlockType );
}
