import $ from 'jquery'

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      arrows: true,
      dots: false,
      mobileFirst: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
          }
        }
      ]
    });
  }
}

export default function initSlider(){
  $(document).ready( initMainSlider )
}
